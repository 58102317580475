$border: 1px solid rgba(#456, .5);

.table {
    border-radius: 10px;
    border: $border;
    overflow: hidden;

    .header {
        background: rgba(0,0,0,.4);
        color: white;
        font-weight: bold;
        padding: 10px;
        text-align: center;
    }

    .col-header {
        @include flex-no-wrap;
        background: rgba(0,0,0,.3);
        border-top: 1px solid $border;

        .col {
            padding: 10px;
            min-width: 50px;
            border-right: $border;
            flex: 1;
        }
    }

    .row {
        @include flex-no-wrap;

        &:nth-child(even) {
            background: rgba(10,20,30,.2);
        }

        .col {
            padding: 10px;
            min-width: 50px;
            border-right: $border;
            flex: 1;
        }
    }

}

.font-label {
    @include font-label;
}

.font-medium,
.font-md {
    @include font-medium;
}

.font-large,
.font-lg {
    @include font-large;
}

.font-xlarge,
.font-xl {
    @include font-xlarge;
}

.font-avenir {
    @include font-avenir;
}

.font-plain {
    @include font-plain;
}

.font-bold {
    @include font-bold;
}

.font-condensed {
    @include font-condensed;
}

.font-mono {
    @include font-mono;
}

.font-error {
    @include font-error;
    // @include flex-col-center;
    // width: 100%;
    // margin: 0 auto;
    // text-align: center;
    // font-size: 1.25rem;
    // margin: 0 auto;
}

// New Menu

@mixin font-open-sans {
    font-family: "Open Sans";
}

@mixin font-open-sans-bold {
    font-family: "Open Sans Bold";
}

// FONT / TEXT ////////////////////

@mixin font-plain($size: var(--font-size-default)) {
    font-family: var(--font-default);
    font-size: $size;
    line-height: 1.1em;
    font-weight: 500;
}

$btnFontPadFix: 0.5em;
@mixin font-label {
    @include font-plain(0.85rem);
    line-height: 1em;
    font-weight: 200;
    letter-spacing: 1px;
    //text-transform: uppercase;
}

@mixin font-label-light {
    @include font-label;
    font-weight: 200;
}

@mixin font-input-label {
    @include font-label;
}

@mixin font-input {
    @include font-plain(var(--font-size-input));
    font-family: var(--font-input);
    font-weight: 700;
    letter-spacing: 3px;
    padding-bottom: 0.65em;
}

@mixin font-bold {
    @include font-plain();
    font-weight: 900;
}

@mixin font-condensed {
    @include font-plain();
}

@mixin font-condensed-light {
    @include font-plain();
    font-weight: 200;
}

@mixin font-mono {
    @include font-plain();
}

////////////////////////////////////////
// Non-basic font styles:

@mixin font-condensed-header {
    @include font-condensed-light;
    font-size: 1.35rem;
    line-height: 1em;
    font-weight: 100;
    letter-spacing: 5px;
    text-transform: uppercase;
}

@mixin font-button {
    @include font-label();
    font-family: var(--button-font);
    font-size: var(--button-font-size);
}

@mixin font-button-bold {
    @include font-label();
    font-family: var(--button-font);
    font-size: var(--button-font-size);
    font-weight: 900;
}

/////////////////////////////////

@mixin font-small {
    font-family: var(--font-default);
    font-size: 1rem;
    line-height: 1em;
    font-weight: 500;
}

@mixin font-medium {
    @include font-plain(1.175rem);
}

@mixin font-large {
    @include font-plain(1.6875rem);
}

@mixin font-xlarge {
    @include font-plain(2.25rem);
}

@mixin font-error($bg: "light") {
    @include font-plain(1rem);
    color: var(--color-error);

    @if $bg == "dark" {
        color: var(--color-error-dark);
    }
}

/// CLEANUP: ////////////////////////////

@mixin font-avenir {
    font-family: "Avenir Light";
}

// Todo: eventually break these out into separate files when big enough.

@mixin input-simple {
    @include font-input;
}

@mixin number-font {
    font-family: var(--font-bold-default);
    color: white;
}

@mixin header-mono {
    font-family: var(--font-default);
    letter-spacing: 10px;

    @include mobile {
        letter-spacing: 7px;
    }
}

@mixin header-font {
    font-family: var(--font-default);
}

@mixin header-font-bold {
    font-family: var(--font-bold-default);
}

@mixin graph-header {
    @include header-font;
    @include flex-col;
    @include t6;
    color: white;
    text-transform: uppercase;
    line-height: 2.25rem;
    justify-content: flex-end;
    padding-bottom: 2rem;
}

@mixin section-header {
    $height: 4rem;
    @include flex-center-y;
    font-family: "Open Sans Condensed Light";
    font-family: var(--font-default);
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.25em;
    letter-spacing: 1px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
    color: var(--color-blue-light);
    padding: calc(var(--gutter-default) * 0.4) var(--gutter-default) calc(var(--gutter-default) * 0.15); // var(--gutter-default) calc(var(--gutter-default) * 1.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.015);
    background: linear-gradient(180deg, rgba(51, 98, 109, 0.425) 0%, rgba(19, 35, 75, 0.579) 100%);
    //@include after-shadow(0, $height, 10px);
}

@mixin section-header-large {
    @include section-header;
    font-size: 2.5rem;
}

@mixin section-content {
    font-family: var(--font-default);
    font-size: 1rem;
    line-height: 1.25em;
    padding: var(--gutter-default);
}

@mixin num-io-label {
    font-size: clamp(0.75rem, 0.25vw, 2rem);
    font-family: var(--font-bold-default);
    font-weight: 100;

    @include mobile {
        //font-size: clamp(.75rem, .85vw, 2rem);
        font-size: clamp(0.65rem, 0.5vw, 1.5rem);
    }
}

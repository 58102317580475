@import "/src/styles/includes";

#site-footer {
    $headerColor: #f8f8f8;
    padding: 5vw clamp(20px, 4vw, 40px) 3vh;

    .logo {
        min-height: 25px;
        height: clamp(25px, 7.5vw, 120px);
        margin-bottom: clamp(15px, 1.5vw, 30px);
        margin-left: -5px;

        img {
            height: 100%;
        }
    }

    .cols {
        @include flex-col;
        text-align: left;

        .col {
            @include flex-col;
            text-align: left;
        }
    }

    .section {
        margin: clamp(5px, 0.5vw, 10px) 0;

        h2 {
            color: $headerColor;
            text-transform: uppercase;
            font-size: clamp(16pt, 2vw, 24pt);
            margin-bottom: clamp(5px, 0.25vw, 15px);
        }

        .items {
            @include flex-col;
            align-items: left;
            padding-left: 0;

            li {
                display: block;
                margin: clamp(2px, 1vw, 5px) 0;

                span,
                a,
                > div {
                    font-size: clamp(15pt, 1.5vw, 20pt);
                    outline: none;
                    text-shadow: 1px 1px 5px #000;
                    color: var(--color-link);
                }

                a:hover {
                    color: var(--color-link-hover);
                }

                &:hover {
                    .sub-menu {
                        opacity: 1;
                    }
                }
            }
        }

        &.contact {
            .underline {
                text-decoration: underline;
            }

            .items {
                li a:hover {
                    color: var(--link-color);
                }

                .social {
                    @include flex-row;

                    a {
                        margin-right: 10px;
                        width: clamp(32px, 2.5vw, 48px);
                        padding: 5px;
                        @include hover();
                    }
                }
            }
        }
    }

    @include not-mobile {
        .cols {
            @include flex-row;

            .col {
                @include flex-col;
                flex: 1 0 50%;

                .section {
                    @include flex-col;

                    .items {
                        @include flex-row;
                    }
                }

                &.left {
                    .contact .items {
                        @include flex-col;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                }

                &.right {
                    justify-content: flex-end;

                    .section {
                        align-items: flex-end;
                        margin: clamp(5px, 5vw, 20px) 0;

                        h2 {
                            margin-right: clamp(5px, 1vw, 10px);
                        }

                        .items {
                            align-items: flex-end;

                            li {
                                margin: 0 clamp(5px, 1vw, 10px);
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }

    @include mobile {
        padding: clamp(50px, 20vw, 100px) clamp(20px, 7vw, 40px) 5vh;

        .logo {
            min-height: 80px;
            height: clamp(80px, 18vw, 100px);
            margin-bottom: clamp(15px, 5vw, 30px);
        }

        .section {
            margin: clamp(15px, 2.5vw, 30px) 0;
        }
    }
}

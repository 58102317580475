@import "./includes";
@import "./lib";
@import "./app/vars";

html {
    overflow: hidden;
}

html,
body {
    min-height: 100%;
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    color: var(--color-font);
    font-family: var(--font);
    background: var(--color-background);
    font-size: var(--font-base-size);
}

body {
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

* {
    box-sizing: border-box;
}

#root {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

a {
    outline: none;
    color: var(--color-link);

    &:hover {
        color: var(--color-link-hover);
    }
}

@import "./app/global";
@import "./app/common/index";
@import "./app/controls/index";

@import "./page.scss";

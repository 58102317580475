// sets browser standards

* {
    box-sizing: border-box;
}

html,
body {
    min-height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    overflow-x: hidden;
}

a {
    text-decoration: none;

    &:active {
        background: none;
        outline: none;
    }
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
}

input,
select,
textarea,
iframe {
    // appearance: none;
    // -moz-appearance: none;
    // -webkit-appearance: none;
}

// ::-webkit-input-placeholder {
//    text-transform: uppercase;
// }
// :-moz-placeholder {
//    text-transform: uppercase;
// }
// ::-moz-placeholder {
//    text-transform: uppercase;
// }
// :-ms-input-placeholder {
//    text-transform: uppercase;
// }

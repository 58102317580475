@import "/src/styles/includes";

#lighting {
    padding: 0;
    width: 100%;

    .content {
        text-align: center;
        margin: 0 auto;
        padding: 0;
        width: 100%;

        .slick-slider {
            position: relative;
            height: clamp(100px, 35vw, 800px);
            max-height: 800px;
            width: 100vw;
            overflow: hidden;

            .carousel-img {
                @include flex-col-center-both;
                height: clamp(100px, 35vw, 800px);
                max-height: 800px;
                overflow: hidden;
                width: 100vw;

                img {
                    object-fit: cover;
                    height: clamp(100px, 35vw, 800px);
                    max-height: 800px;
                    min-width: 100%;
                }
            }
        }
    }

    @include mobile {
        .content {
            .slick-slider {
                height: clamp(100px, 65vw, 800px);

                .carousel-img {
                    width: 100vw;
                    height: clamp(100px, 65vw, 800px);

                    img {
                        height: clamp(100px, 65vw, 800px);
                    }
                }
            }
        }
    }
}

* {
    box-sizing: border-box;
}

a {
    color: var(--color-link);
    transition: color var(--animation-time-links) ease;

    &:hover {
        color: var(--color-link-hover);
        transition: color var(--animation-time-links) ease;
    }
}

img {
    max-width: 100%;
}

.clear {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.hidden {
    display: none;
}

.hidden-imp {
    display: none !important;
}

.ant-notification {
    z-index: 999999;
}

$minColWidth: 400px;
$cardMargin: 15px;
$cardPadding: 25px;
$cardRadius: 25px;
$cardBackground: rgba(50, 55, 60, 0.9);
$pageTextColor: #ffff;

@mixin card {
    @include flex-col;
    @include gradient-blue;
    border-radius: $cardRadius;
    margin: $cardMargin;
    padding: $cardPadding;
    color: $pageTextColor;
    font-weight: normal;
    min-width: $minColWidth;
    position: relative;
    transform: translateX(-$cardMargin);
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);

    .header {
        margin-bottom: $cardPadding;
    }

    &.is-selected {
        background: var(--color-blue-light);
    }

    &.add-new {
        cursor: pointer;
    }

    &:first-child {
    }

    &:last-child {
    }

    &.icon {
        &:not(.add-new):after {
            @include flex-center-both;
            content: "i";
            font-size: 1.5rem;
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: 2px solid #000;
        }

        &.add-new:after {
            @include flex-center-both;
            content: "+";
            font-size: 2.5rem;
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: 2px solid #000;
        }
    }
}

@mixin card-hover {
    @include card;

    &:hover {
        @include gradient-blue-hover;
        cursor: pointer;
    }
}

@mixin card-list {
    @include flex-row;
    padding: $cardMargin 0;

    .card {
        @include card;
    }
}

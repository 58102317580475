@import "/src/styles/includes";

#audio {
    .banner {
        .img-lazy {
            .img {
                &.full {
                    img {
                        top: -100px;
                    }
                }
            }
        }
    }

    .items {
        @include flex-col-center-x;

        .item {
            @include flex-col-center-x;
            @include hover;
            margin: clamp(10px, 15vw, 80px);
            text-align: center;

            .image {
                @include flex-col-center-both;
                max-height: 300px;
                height: clamp(200px, 30vw, 400px);
                transition: opacity 0.1s linear;
                margin-bottom: 5px;

                img {
                    height: 100%;
                }
            }

            .title {
                text-transform: uppercase;
                font-size: clamp(18pt, 3vw, 28pt);
                color: var(--color-red);
                margin: clamp(15px, 4vw, 30px) 0 clamp(10px, 4vw, 20px); //clamp(10px, 1vw, 40px);
                transition: color 0.2s linear;
            }
        }

        .button {
            width: 200px;
            margin: 0 auto;
        }
    }

    @include mobile {
        .banner {
            .img-lazy {
                .img {
                    &.full {
                        img {
                            top: -100px;
                        }
                    }
                }
            }
        }

        .items {
            padding: var(--gutter) 0;
        }
    }
}

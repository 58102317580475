.page {
    $gutter: clamp(10px, 7vw, 100px);
    $gutterhalf: clamp(10px, 3.5vw, 50px);

    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    h1 {
        font-size: 16pt;
        padding: 20px 40px;
    }

    .slick-slider {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);

        .button {
            display: none;
        }

        .slick-list {
            height: 100%;

            .slick-track {
                height: 100%;

                .slick-slide {
                    > div {
                        height: 100%;
                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .banner {
        position: relative;
        width: 100%;
        height: calc(100vh - var(--header-height));
        @include flex-col-center-both;
        overflow: hidden;
        box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.5);

        .title {
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: clamp(16pt, 9vw, 80pt);
            padding: 0 clamp(15px, 5vw, 40px);
            text-align: center;
            margin: 0 auto;
            text-transform: uppercase;
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
            background: rgb(0, 0, 0);
            background: radial-gradient(circle, rgba(0, 0, 0, 0.75) 5%, rgba(0, 0, 0, 0) 75%);
        }

        .children {
            @include flex-col-center-both;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        width: 100%;
        margin: 0 auto;

        // About, etc, long text pages
        .blurb {
            padding: clamp(20px, 3vw, 80px) clamp(15px, 12vw, 50px);
            font-size: clamp(12pt, 2.5vw, 24pt);
            margin: 0 auto;
            text-align: center;
            line-height: 1.25em;

            &.darker {
                background: #0c0c0d;
            }

            p {
                text-align: center;
                margin: clamp(15px, 5vw, 40px) auto;
                max-width: clamp(300px, 50vw, 1000px);
            }
        }

        // For "Services" list sections
        .sections {
            padding: clamp(20px, 4vw, 50px) 0;

            .section {
                width: clamp(600px, 60vw, 900px);
                margin: clamp(25px, 10vw, 120px) auto;

                .title {
                    text-transform: uppercase;
                    font-size: clamp(18pt, 2.5vw, 34pt);
                    color: var(--color-red);
                    margin: clamp(15px, 4vw, 50px) 0 clamp(10px, 2vw, 30px); //clamp(10px, 1vw, 40px);
                    text-decoration: underline;
                    text-align: center;
                }

                .list {
                    @include flex-row;
                    flex-wrap: wrap;
                    text-align: left;
                    justify-content: flex-start;
                    align-items: flex-start;
                    list-style-type: circle;
                    margin: 0 clamp(20px, 7vw, 50px);

                    @include not-mobile {
                        margin-left: clamp(20px, 12vw, 180px);
                    }

                    li {
                        position: relative;
                        @include flex-col-center-x;
                        width: 50%;
                        max-width: calc(50% - 10px);
                        margin: clamp(5px, 1vh, 10px) 0;
                        align-items: flex-start;
                        font-size: clamp(16pt, 2vw, 24pt);
                        padding: 0 clamp(20px, 7vw, 30px);
                        color: #ddd;
                        transition: color 0.2s linear;

                        &::before {
                            position: absolute;
                            top: calc(50% - 4px);
                            left: 0px;
                            content: "";
                            width: 8px;
                            height: 8px;
                            background: white;
                            border-radius: 8px;
                        }

                        &:hover {
                            color: #fff;
                            transition: color 0.2s linear;
                        }
                    }

                    &.full {
                        @include flex-col;

                        li {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .contact-banner {
        background: #0c0c0d;
        padding: clamp(50px, 10vw, 150px);

        &.no-bg {
            background: none;
        }

        .button {
            width: clamp(220px, 12vw, 300px);
            margin: 0 auto;
        }
    }

    .pad {
        padding-top: var(--gutter);
        padding-bottom: var(--gutter);
    }

    .pad-half {
        padding-top: var(--gutter-half);
        padding-bottom: var(--gutter-half);
    }

    .pad-top {
        padding-top: var(--gutter);
    }

    .pad-top-half {
        padding-top: var(--gutter-half);
    }

    .pad-bottom {
        padding-bottom: var(--gutter);
    }

    @include mobile {
        .banner {
            height: var(--banner-height);
            min-height: var(--banner-height);
        }

        .content {
            .blurb {
                font-size: clamp(14pt, 4vw, 24pt);
                padding: clamp(20px, 7vw, 50px) clamp(20px, 12vw, 70px);

                p {
                    max-width: clamp(300px, 85vw, 1024px);
                }
            }

            .sections {
                .section {
                    @include flex-col-center-x;
                    width: clamp(300px, 100vw, 1024px);

                    .list {
                        margin: 0 auto;
                        align-items: center;
                        justify-content: center;

                        li {
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;
                            padding: 0 clamp(10px, 10vw, 20px);
                            margin-left: 0;
                            text-align: center;
                            align-items: center;
                            justify-content: center;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .contact-banner {
            padding: clamp(50px, 20vw, 150px);
        }
    }
}

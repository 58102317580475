// these should NOT use print out any real css, virtual only!
// put non-virtual variables in app.scss

// TODO: these CSS vars should load the vars from actuall SASS variables, ie. $, so the variables can be passed around at compile time more easily, and still received here.
// TODO: write a functoin to convert/store sass vars to these css vars.
@import "./includes";

$gutter: clamp(40px, 4.5vw, 80px);
$gutterhalf: clamp(20px, 2.25vw, 40px);
$headerHeight: clamp(120px, 10vh, 150px);
$headerHeightMobile: 70px;

:root {
    --font-size-default: 16px;
    --font-default: "DIN Condensed";
    --font-bold-default: "DIN Condensed";
    --font-input: "DIN Condensed"; //var(--font-default);
    --font-dropdown: var(--font-input);
    --font-button: var(--font-input);

    // map out app colors to vars:
    @each $color, $value in $colors {
        --color-#{$color}: #{$value};
    }

    --color-red: #bc3232;
    --color-blue: #88d4d4;
    --color-grey: #cfcfcf;

    /* color definitions */
    --color-font: var(--color-grey-9);
    --color-text: var(--color-font);
    --color-error: var(--color-red);
    --color-error-dark: var(--color-red-dark);

    --color-input: var(--color-grey-1);
    --color-input-placeholder: var(--color-grey-4);
    --color-input-dark: var(--color-grey-9);
    --color-input-placeholder-dark: var(--color-grey-7);

    --color-link: #a09595;
    --color-link-hover: #fff;

    --button-font: "DIN Condensed";
    --button-font-size: 1.5rem;
    --button-text-color: var(--color-blue-light);
    --button-text-color-hover: white;
    --button-color-bg: none;
    --button-background-color: #0069d2;
    --button-height: 3.5rem;
    --button-radius: 0;
    --button-border-width: 2px;
    --button-bar-height: var(--button-height);
    //////////////////////////////

    --caret-color: white;
    --caret-color-dark: var(--color-blue-dark);

    --input-padding: 0.75rem;
    --input-font-size: 1.25rem;

    --modal-radius: calc(2 * var(--button-radius));
    --modal-background: white;
    --modal-color-font: var(--color-grey-3);

    // Spacing / sizes:
    --header-height: clamp(120px, 8vw, 150px);
    --banner-height: calc(100vh - var(--header-height));
    --banner-height-full: calc(100vh - var(--header-height));

    --default-padding: 20px;
    --default-gutter: clamp(4rem, 3vw, 6rem);

    // old (refactor):
    --gutter: clamp(40px, 4.5vw, 80px);
    --gutter-half: clamp(20px, 2.25vw, 40px);
    --gutter-default: clamp(40px, 4.5vw, 80px);
    --gutter-default-static: 15px;
    --gutter-large: clamp(2rem, 2vw, 6rem);

    --dropdown-background: rgba(20, 38, 67, 1);
    --dropdown-border-color: #fff;
    --dropdown-border-width: 1px;
    --dropdown-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
    --dropdown-shadow-light: 1px 0px 5px rgba(0, 0, 0, 0.1);

    --animation-time: 0.2s;
    --animation-time-links: 0.1s;

    @include mobile {
        --header-height: 70px;
        --banner-height: clamp(200px, 20vh, 500px); //calc(100vh - var(--header-height));
        --banner-height-full: calc(100vh - var(--header-height));

        --button-height: 2.85rem;
        --button-font-size: 1.25rem;

        --gutter: clamp(20px, 12vw, 100px);
        --gutter-half: clamp(10px, 6vw, 50px);
        --gutter-default: 4vw;
        --gutter-default-static: 15px;
        --gutter-large: 8vw;

        --default-padding: 15px;
    }
}


.error {
    @include font-error;
    color: var(--color-error-dark);
    @include font-plain;
    font-size: 1.25rem;
    line-height: 1.35rem;

    &.full {
        @include flex-col-center-both;
        margin: 0 auto;
        width: 100%;
        padding: var(--gutter-default);
    }

    &.small {
        @include font-small;
    }

    &.large {
        @include font-large;
    }

    // make the label itself a little bigger
    .error-label {
        margin: 5px 0 15px;
        font-size: 1.25em;
        color: lighten(#ff7158, 10%);
    }

    &.dark {
        @include font-error('dark');

        .error-label {
            color: lighten(#f78, 10%);
        }
    }
}

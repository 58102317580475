/* text */

.font-label {
    @include font-label;
}

.font-large,
.font-lg {
    @include font-large;
}

.font-medium,
.font-md {
    @include font-medium;
}

.font-label-light {
    @include font-label-light;
}

/////////////////////

.blue {
    color: var(--color-blue);

    &.bg {
        background-color: var(--color-blue);
        color: white;
    }
}

.blue-dark {
    color: var(--color-blue-dark);

    &.bg {
        background-color: var(--color-blue-dark);
        color: white;
    }
}

.green {
    color: var(--color-green);

    &.bg {
        background-color: var(--color-green);
        color: var(--color-blue-dark);
    }
}
.img-lazy {
    position: relative;
    @include flex-col-center-both;
    width: 100%;
    height: 100%;

    .img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 1s ease-in-out;
        @include flex-col-center-both;

        &.thumb {
            opacity: 0;
            z-index: 1;
            transition: opacity 1s ease-in-out;

            img {
                filter: blur(30px);
            }

            &.loaded {
                opacity: 1;
                transition: opacity 1s ease-in-out;
            }
        }

        &.full {
            opacity: 0;
        }
    }

    img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    &.loaded {
        .img {
            &.thumb {
                opacity: 0;
                transition: opacity 1s ease-in-out;
            }

            &.full {
                opacity: 1;
                transition: opacity 1s ease-in-out;
            }
        }
    }
}

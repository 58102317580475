@import "/src/styles/includes";

#site-header {
    $bgColor: #0c0c0d;
    $height: var(--header-height);
    $heightMobile: 70px;
    $topLinkColorHover: #a09595;
    $menuFontSizeMobile: clamp(16pt, 6vh, 22pt);
    $menuPaddingMobile: clamp(10px, 1.75vh, 50px);

    position: relative;
    background: $bgColor;
    @include flex-row-center-both;
    z-index: 20;
    height: $height;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.45);

    .logo {
        flex: 1 0 33%;
        @include flex-center-y;
        margin: 0 auto 0 clamp(20px, 3vw, 80px);
        height: 100%;
        width: 100%;
        z-index: 1;
        padding: 10px;

        a {
            display: block;
            max-height: 160px;
            height: clamp(60px, 4.5vw, 80px);
            width: clamp(200px, 10vw, 500px);
            text-align: left;
            align-items: flex-start;
            padding: clamp(5px, 2vw, 10px);
            margin-top: 12px;

            .img-lazy {
                align-items: flex-start;
                justify-content: flex-start;
                text-align: left;
            }

            img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }

    .menu {
        flex: 2 0 66%;
        position: relative;
        width: 100%;
        height: 100%;
        @include flex-col;
        align-items: flex-end;
        justify-content: flex-end;
        margin: auto 0 0 auto;

        .control {
            display: none;
        }

        .overlay {
            position: relative;
            width: 100%;
            height: 100%;
            @include flex-col;
            align-items: flex-end;
            justify-content: space-between;
        }

        .items {
            @include flex-row;
            align-items: flex-end;
            justify-content: flex-end;
            padding-right: clamp(10px, 1vw, 50px);
            margin: 0;

            li {
                position: relative;
                @include flex-center-both;
                margin: 0 clamp(10px, 1.5vw, 25px);

                a {
                    @include flex-center-both;
                    height: 100%;
                    padding: clamp(5px, 1vw, 20px) clamp(10px, 1.5vw, 20px);
                    background: $bgColor;
                    font-size: clamp(18pt, 2vw, 25pt);
                    text-align: center;
                    outline: none;
                    text-shadow: 1px 1px 5px #000;
                    z-index: 10;
                    transition: color 0.1s linear;
                    color: var(--color-grey);
                    white-space: nowrap;

                    &:hover {
                        color: var(--color-white);
                        transition: color 0.1s linear;
                    }
                }

                .arrow {
                    max-width: 16px;
                    margin: 2px 0 0 clamp(10px, 1.25vw, 20px);
                }

                .sub-menu {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    opacity: 0;
                    z-index: 9;
                    transition:
                        opacity 0.15s ease-in-out,
                        top 0.15s ease-in-out;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    overflow: hidden;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);

                    a {
                        background: $bgColor;

                        &:hover {
                            background: linear-gradient(0deg, rgb(25, 25, 25) 0%, rgb(20, 20, 20) 40%);
                        }
                    }
                }

                &:hover {
                    .sub-menu {
                        opacity: 1;
                        top: 100%;
                        transition:
                            opacity 0.25s ease-in-out,
                            top 0.25s ease-in-out;
                    }
                }
            }

            &.bottom {
                position: absolute;
                height: 100%;

                > li {
                    height: 100%;

                    > a {
                        padding-top: clamp(12px, 2.5vw, 60px);
                    }
                }
            }

            &.top {
                @include flex-row-center-y;
                justify-content: flex-end;
                background: $bgColor;
                background: linear-gradient(90deg, $bgColor 0%, rgba(0, 0, 0, 1) 100%);
                margin: 0 0 0 auto;
                padding-right: clamp(25px, 2vw, 45px);
                width: 61.8%;
                z-index: 11;

                li {
                    @include flex-center-both;
                    margin: 0 clamp(15px, 1.75vw, 25px);

                    a {
                        @include flex-center-both;
                        padding: clamp(5px, 0.75vw, 10px) 0px;
                        font-size: clamp(12pt, 1.25vw, 16pt);
                        text-align: center;
                        outline: none;
                        background: none;
                        white-space: nowrap;
                        color: var(--color-grey);
                        transition: color 0.1s linear;

                        &:hover {
                            color: var(--color-white);
                            transition: color 0.1s linear;
                        }
                    }
                }
            }
        }
    }

    @include mobile {
        @include flex-center-both;
        height: $heightMobile;
        z-index: 99;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);

        .logo {
            @include flex-col-fill-center-both;
            height: 100%;
            width: 100vw;
            max-width: 100vw;
            background: $bgColor;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
            padding: 0;
            margin: 0;

            a {
                @include flex-col-center-both;
                //height: calc(#{$heightMobile} / 1.6);
                height: 100%;
                padding: clamp(10px, 4vw, 20px);
                padding-top: clamp(5px, 4vw, 10px);
            }
        }

        .menu {
            position: absolute;
            right: 0;
            height: $heightMobile;
            width: 100vw;

            .control {
                z-index: 1;
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: $bgColor;
                height: $heightMobile;
                width: $heightMobile;
                padding: 0;
                opacity: 0.8;
                transition: 0.2s linear opacity;
                position: absolute;
                top: 0;
                right: 0;

                .image {
                    height: 100%;
                    width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        padding: 20px;
                    }
                }

                &:hover {
                    opacity: 1;
                    transition: 0.2s linear opacity;
                }

                &.close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                }
            }

            .overlay {
                @include flex-col-center-both;
                position: fixed;
                top: -100vh;
                right: 0;
                left: 0;
                height: 100vh;
                min-height: 100vh;
                max-height: 100vh;
                //padding-top: calc(var(--header-height) / 2);
                overflow-y: auto;
                background: $bgColor;
                background: radial-gradient(circle, rgba(8, 8, 9, 1) 0%, rgba(12, 12, 13, 1) 50%);

                .close {
                    z-index: 2;
                    background: blue;
                }

                .items {
                    @include flex-col-center-both;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: auto;
                    min-height: initial;
                    transition: top 0.35s ease-in-out;
                    padding: 0;
                    justify-content: flex-start;
                    background: none;

                    li {
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        text-align: center;

                        a {
                            width: 100%;
                            font-size: $menuFontSizeMobile;
                            padding: $menuPaddingMobile;
                            margin: 0;
                            text-shadow: 0px 0px 10px #000;
                        }

                        .arrow {
                            display: none;
                        }

                        .sub-menu {
                            display: none;
                        }
                    }

                    &.top {
                        display: none;
                        order: 2;
                    }

                    &.bottom {
                        order: 1;
                        height: auto;
                        position: relative;

                        li {
                            height: auto;

                            a {
                                background: none;
                            }
                        }
                    }
                }
            }

            &.open {
                .overlay {
                    top: 0;
                    transition: top 0.35s ease-in-out;

                    .top {
                        @include flex-col-center-both;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        height: auto;
                        background: none;

                        li {
                            a {
                                width: 100%;
                                font-size: $menuFontSizeMobile;
                                padding: $menuPaddingMobile;
                                margin: 0;

                                &:hover {
                                    color: var(--color-white);
                                }
                            }
                        }
                    }
                }
            }

            &.closed {
                .overlay {
                    top: -100vh;
                    transition: top 0.35s ease-in-out;
                }
            }
        }
    }
}

@import "/src/styles/includes";

#gallery {
    .content {
        @include flex-col-center-both;

        .grid {
            @include flex-row-center-both;
            flex-wrap: wrap;
            width: clamp(390px, 90vw, 1800px);
            margin: 0 auto;
            align-items: flex-start;
            justify-content: center;
            box-sizing: content-box;

            li {
                position: relative;
                @include flex-col-center-both;
                width: calc(clamp(120px, 30vw, 400px) - clamp(5px, 0.35vw, 15px));
                height: calc(clamp(120px, 30vw, 400px) - clamp(5px, 0.35vw, 15px));
                margin: clamp(5px, 0.35vw, 15px);
                background: #444;

                .caption {
                    @include flex-col-center-both;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    padding: 5px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    font-size: clamp(12pt, 4vw, 18pt);
                    opacity: 0;
                    transition: opacity 0.35s ease-in-out;
                    z-index: 3;
                }

                .loading {
                    position: absolute;
                    align-self: center;
                    justify-self: center;
                    z-index: 0;
                }

                img {
                    min-width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                    opacity: 1;
                    z-index: 1;
                    transition:
                        opacity 0.35s ease-in-out,
                        box-shadow 0.35s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    .caption {
                        opacity: 1;
                        transition: opacity 0.35s ease-in-out;
                    }
                    // img {
                    //     box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.2);
                    //     opacity: 1;
                    //     transition:
                    //         opacity 0.35s ease-in-out,
                    //         box-shadow 0.35s ease-in-out;
                    // }
                }
            }
        }

        .popup {
            position: relative;
            @include flex-col-center-both;
            position: fixed;
            top: 100vh;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
            padding: clamp(25px, 15vw, 200px);
            visibility: visible;

            .bg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(180, 180, 180, 0.85);
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                z-index: 0;
            }

            .inner {
                position: relative;
                top: 100vh;
                @include flex-col-center-both;
                //width: clamp(300px, 75vw, 1200px);
                z-index: 1;
                transition: top 0.5s ease-in-out;

                .image {
                    @include flex-col-center-both;
                    height: 100%;
                    position: relative;
                    min-height: 10rem;

                    .loading {
                        position: absolute;
                        align-self: center;
                        justify-self: center;
                        font-size: 1.5rem;
                    }

                    a {
                        display: block;
                        z-index: 1;

                        img {
                            object-fit: contain;
                            border-radius: 5px;
                            max-height: 80vh;
                            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
                            transition: box-shadow 0.2s linear;

                            &:hover {
                                box-shadow: 0px 0px 15px rgba(255, 245, 230, 1);
                                transition: box-shadow 0.2s linear;
                            }
                        }
                    }
                }

                .caption {
                    width: 100%;
                    text-align: center;
                    font-size: clamp(16pt, 2vw, 24pt);
                    color: #333;
                    margin-bottom: clamp(10px, 2vw, 20px);
                }
            }

            &.open {
                top: 0;

                .bg {
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                }

                .inner {
                    top: 0;
                    transition: top 0.5s ease-in-out;

                    .close {
                        opacity: 1;
                        transition: opacity 0.25s linear;
                    }
                }
            }

            .close {
                position: absolute;
                top: clamp(40px, 2vw, 80px);
                right: clamp(40px, 2.5vw, 80px);
                height: clamp(32px, 4vw, 64px);
                cursor: pointer;
                @include hover();

                &:hover {
                    cursor: pointer;
                }

                img {
                    height: 100%;
                }
            }
        }
    }

    @include mobile {
        .content {
            padding-top: 5px;

            .grid {
                width: 100vw;
                margin: 0 auto;
                padding: 0;

                li {
                    @include flex-col-center-both;
                    width: calc(32% - 4px); //width: clamp(60px, 33vw, 400px);
                    //height: clamp(120px, 45vw, 400px);
                    margin: 3px; // clamp(5px, 0.35vw, 15px);

                    .caption {
                        display: none;
                    }
                }
            }

            .popup {
                .inner {
                    width: clamp(300px, 90vw, 1024px);
                }

                .close {
                    top: clamp(20px, 1vw, 40px);
                    right: clamp(20px, 4vw, 40px);
                }
            }
        }
    }
}

@mixin hover($offOpacity: 0.75, $onOpacity: 1, $time: 0.2s, $curve: linear) {
    opacity: $offOpacity;
    transition: opacity $time $curve;

    &:hover {
        opacity: $onOpacity;
        transition: opacity $time $curve;
    }

    @include mobile {
        opacity: 1;
    }
}

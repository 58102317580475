@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

@function get-color($color: "") {
    @if not map-has-key($colors, $color) {
        @warn "'#{$color}' is not a known color";
    }
    @return map-get($colors, $color);
}

@mixin gradient-blue {
    background: #1e2a3b; //rgb(29, 52, 73);
    background: linear-gradient(165deg, #356078 0%, #1e2a3b 100%);
}

@mixin gradient-blue-hover {
    background: #2b3d56; //rgb(29, 52, 73);
    background: linear-gradient(178deg, #6392aa 0%, #3b6d88 40%, #3b5e7e 70%, #264858 100%);
}

@mixin gradient-blue-hover-2 {
    background: #354b6a; //rgb(29, 52, 73);
    background: linear-gradient(178deg, #6a9cb5 0%, #3d718d 40%, #3e6386 70%, #284c5c 100%);
}

@mixin gradient-blue-fade-top-bottom {
    // background: rgb(24, 51, 57);
    // background: linear-gradient(180deg, rgba(24, 51, 57, 0) 0%, rgba(8, 11, 33, 0.5) 50%, rgba(24, 51, 57, 0) 100%);
    background: rgb(8, 11, 33);
    background: radial-gradient(circle, rgba(8, 11, 33, 0.5) 0%, rgba(24, 48, 57, 0) 45%);
}

@mixin gradient-blue-horizontal {
    background: var(--color-gradient-blue-base);
    background: linear-gradient(
        90deg,
        rgba(21, 149, 208, 1) 0%,
        rgba(21, 132, 208, 1) 10%,
        rgba(21, 116, 208, 1) 20%,
        rgba(21, 103, 208, 1) 30%,
        rgba(21, 93, 208, 1) 40%,
        rgba(21, 84, 208, 1) 50%,
        rgba(21, 77, 208, 1) 60%,
        rgba(21, 73, 208, 1) 70%,
        rgba(21, 70, 208, 1) 80%,
        rgba(21, 69, 208, 1) 90%,
        rgba(21, 69, 208, 1) 100%
    );
}

@mixin gradient-blue-reverse {
    background: var(--color-gradient-blue-base);
    background: linear-gradient(
        180deg,
        rgba(21, 69, 208, 1) 0%,
        rgba(21, 69, 208, 1) 10%,
        rgba(21, 70, 208, 1) 20%,
        rgba(21, 73, 208, 1) 30%,
        rgba(21, 77, 208, 1) 40%,
        rgba(21, 84, 208, 1) 50%,
        rgba(21, 93, 208, 1) 60%,
        rgba(21, 103, 208, 1) 70%,
        rgba(21, 116, 208, 1) 80%,
        rgba(21, 132, 208, 1) 90%,
        rgba(21, 149, 208, 1) 100%
    );
}

@mixin gradient-blue-reverse-short {
    background: var(--color-gradient-blue-base);
    background: linear-gradient(
        180deg,
        rgba(21, 93, 208, 1) 60%,
        rgba(21, 103, 208, 1) 70%,
        rgba(21, 149, 208, 1) 100%
    );
}

@mixin gradient-magenta {
    background: rgb(134, 85, 192);
    background: linear-gradient(
        180deg,
        rgba(134, 85, 192, 1) 0%,
        rgba(137, 85, 191, 1) 10%,
        rgba(142, 85, 189, 1) 20%,
        rgba(148, 85, 187, 1) 30%,
        rgba(155, 86, 184, 1) 40%,
        rgba(162, 86, 182, 1) 50%,
        rgba(171, 86, 179, 1) 60%,
        rgba(179, 86, 175, 1) 70%,
        rgba(188, 86, 172, 1) 80%,
        rgba(198, 87, 169, 1) 90%,
        rgba(208, 87, 165, 1) 100%
    );
}

@mixin gradient-green($opacity: 50%) {
    background: rgba(146, 252, 200, $opacity);
    background: linear-gradient(
        180deg,
        rgba(146, 252, 200, $opacity) 0%,
        rgba(146, 252, 195, $opacity) 10%,
        rgba(147, 252, 183, $opacity) 20%,
        rgba(147, 252, 174, $opacity) 30%,
        rgba(148, 252, 163, $opacity) 40%,
        rgba(148, 252, 154, $opacity) 50%,
        rgba(149, 252, 144, $opacity) 60%,
        rgba(149, 252, 139, $opacity) 70%,
        rgba(149, 252, 139, $opacity) 80%,
        rgba(149, 252, 139, $opacity) 90%,
        rgba(149, 252, 139, $opacity) 100%
    );
}

@mixin gradient-dark-green {
    background: rgb(0, 117, 132);
    background: linear-gradient(180deg, rgba(26, 148, 158, 1) 0%, rgba(0, 99, 110, 1) 100%);
}

@mixin gradient-dark-green-hover {
    $increase: 5%;
    background: lighten(rgb(0, 117, 132), $increase);
    background: linear-gradient(
        180deg,
        lighten(rgba(26, 148, 158, 1), $increase) 0%,
        lighten(rgba(0, 99, 110, 1), $increase) 100%
    );
}

@mixin gradient-dark-green-selected {
    $increase: 10%;
    background: lighten(rgb(0, 117, 132), $increase);
    background: linear-gradient(
        180deg,
        lighten(rgba(26, 148, 158, 1), $increase) 0%,
        lighten(rgba(0, 99, 110, 1), $increase) 100%
    );
}

@mixin gradient-purple {
    background: rgb(145, 113, 255);
    background: linear-gradient(180deg, rgba(145, 113, 255, 1) 0%, rgba(99, 62, 234, 1) 100%);
}

@mixin after-shadow($offsetX: 0, $offsetY: 100%, $height: 5px, $shadow: null) {
    &::after {
        position: absolute;
        z-index: 0;
        content: "";
        top: $offsetY;
        left: $offsetX;
        height: $height;
        width: 100%;
        @if ($shadow) {
            background: $shadow;
        } @else {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        }
        @content;
    }
}

@import "/src/styles/includes";

#dj-backline {
    .items {
        @include flex-col-center-x;

        .item {
            @include flex-col-center-x;
            @include hover(0.7);
            margin: clamp(20px, 7vw, 100px);
            transition: opacity 0.2s linear;

            .image {
                @include flex-col-center-both;
                max-width: 400px;
                max-height: 200px;
                height: auto; // clamp(200px, 30vw, 400px);

                img {
                    max-width: 400px;
                    max-height: 250px;
                }
            }

            .title {
                text-transform: uppercase;
                font-size: clamp(18pt, 2.5vw, 34pt);
                color: var(--color-red);
                margin: clamp(15px, 4vw, 50px) 0 clamp(10px, 2vw, 30px); //clamp(10px, 1vw, 40px);
                text-decoration: underline;
            }

            .description {
                max-width: clamp(500px, 40vw, 800px);
                text-align: center;
                font-size: clamp(14pt, 1.5vw, 24pt);
                line-height: clamp(2rem, 2vw, 2.5rem);
            }
        }

        .button {
            width: 200px;
            margin: 0 auto;
        }
    }

    @include mobile {
        .items {
            .item {
                opacity: 1;
                margin: clamp(30px, 12vw, 100px);

                .image {
                    max-width: 200px;
                    max-height: 200px;
                    height: auto; // clamp(200px, 30vw, 400px);

                    img {
                        max-width: 200px;
                        max-height: 200px;
                    }
                }
            }
        }
    }
}

@import "/src/styles/includes";

#app {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    .app-shell {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        min-height: 100%;
        width: 100%; // clamp(800px, 70vw, 1200px);
        margin: 0 auto;
        background: #111;
    }

    @include mobile {
        .app-shell {
            width: 100%;
        }
    }
}

@import "/src/styles/includes";

#video {
    padding: 0;
    width: 100%;

    .content {
        text-align: center;
        margin: 0 auto;
        padding: 0;
        width: 100%;

        .slick-slider {
            position: relative;
            height: clamp(100px, 35vw, 800px);
            max-height: 800px;
            width: 100vw;
            overflow: hidden;

            .carousel-img {
                @include flex-col-center-both;
                height: clamp(100px, 35vw, 800px);
                max-height: 800px;
                overflow: hidden;
                width: 100vw;

                img {
                    object-fit: cover;
                    height: clamp(100px, 35vw, 800px);
                    max-height: 800px;
                    min-width: 100%;
                }
            }
        }

        .sections {
            .services {
                width: clamp(600px, 70vw, 1000px);

                .list {
                    margin-left: clamp(20px, 10vw, 140px);
                }
            }
        }
    }

    @include mobile {
        .content {
            .slick-slider {
                height: clamp(100px, 65vw, 800px);

                .carousel-img {
                    width: 100vw;
                    height: clamp(100px, 65vw, 800px);

                    img {
                        height: clamp(100px, 65vw, 800px);
                    }
                }
            }

            .sections {
                .services {
                    width: clamp(300px, 100vw, 1024px);

                    .list {
                        @include flex-col;
                        margin: 0;

                        li {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@import "/src/styles/includes";

/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root #home .cards .card .inner .title.safari_only {
    padding-top: 0.25em !important;
}

// normal
#home {
    $gutter: clamp(40px, 4.5vw, 80px);
    $gutterhalf: clamp(18px, 4vw, 40px);
    $headerHeight: clamp(120px, 8vw, 150px);
    $headerHeightMobile: 70px;

    width: 100%;

    @include mobile {
        $gutter: clamp(60px, 6vw, 100px);
    }

    .banner {
        width: 100%;
        height: var(--banner-height-full);
        min-height: var(--banner-height-full);
        @include flex-col-center-both;

        .img-lazy {
            @include flex-col-center-both;
            height: 100%;
            width: 100vw;

            .img {
                @include flex-col-center-both;
                min-height: 100%;
                min-width: 100%;
            }

            img {
                object-fit: cover;
                min-height: 100%;
                min-width: 100%;
            }
        }

        .slick-slider {
            height: 100%;
            width: 100vw;
            box-shadow: none;
        }
    }

    .cards {
        position: relative;
        width: 100%;
        max-width: 1800px;
        margin: 0 auto;
        padding: $gutterhalf $gutter 0;
        @include flex-row-center-x;
        flex-wrap: wrap;

        .card {
            position: relative;
            @include flex-col-center-both;
            margin: 0;
            height: clamp(300px, 30vw, 560px);
            width: 50%;
            max-width: 50%;
            padding: $gutterhalf;

            > a {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 5px;
                box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.35);
                transition: box-shadow linear 0.2s;
                color: white;

                .title {
                    @include flex-col-center-both;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    z-index: 16;
                    font-size: clamp(14pt, 2vw, 30pt);
                    height: 1.75em;
                    line-height: 1em;
                    text-shadow: 1px 1px 1px #555353;
                    color: rgb(215, 215, 215);
                    transition:
                        background-color linear 0.2s,
                        color linear 0.2s;
                    background: rgba(50, 50, 50, 0.5);
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    @include flex-col-center-both;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 15;
                    transition: linear 0.2s opacity;
                    line-height: 1.25em;
                    text-align: center;
                    padding: 1vw clamp(25px, 5vw, 100px) clamp(40px, 7vw, 80px);
                    font-size: clamp(14pt, 1.75vw, 24pt);
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
                }
            }

            &:hover {
                cursor: pointer;

                .inner {
                    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
                    transition: box-shadow linear 0.2s;
                }

                .overlay {
                    opacity: 1;
                    transition: linear 0.2s opacity;
                }

                .title {
                    color: rgb(255, 255, 255);
                    background: rgba(50, 50, 50, 0.75);
                    transition:
                        background-color linear 0.2s,
                        color linear 0.2s;
                }
            }

            .bg {
                @include flex-col-center-both;
                z-index: 0;
                height: 100%;
                object-fit: cover;

                img {
                    min-height: 100%;
                    min-width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    @include mobile {
        .content {
            padding-top: 0;
        }

        .cards {
            padding: calc(#{$gutter}/ 2) $gutterhalf $gutterhalf;

            .card {
                width: 100%;
                max-width: 100%;
                padding: calc(1.15 * #{$gutterhalf}) $gutterhalf;
                height: clamp(60vw, 70vw, 600px);

                .inner {
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);

                    .title {
                        font-size: clamp(14pt, 6vw, 35pt);
                        background: rgba(50, 50, 50, 0.5);
                    }

                    .overlay {
                        padding: 0 clamp(30px, 10vw, 100px) clamp(40px, 12vw, 120px);
                        font-size: clamp(12pt, 4.5vw, 32pt);
                        line-height: 1.05em;
                    }
                }

                &:hover,
                &.show {
                    .inner {
                        .title {
                            color: white;
                            background: rgba(50, 50, 50, 0.65);
                        }

                        .overlay {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @include chrome {
    }
}

// mac chrome desktop only fixes
.mac.chrome {
    #home {
        .cards {
            .card {
                .inner {
                    .title {
                        padding-top: 0.25em;
                    }
                }
            }
        }
    }
}

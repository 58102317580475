@import "./mixins/breakpoints";

:root {
    // (dont use these, deprecated):
    --defaultItemPadding: 15px;

    --defaultPagePadding: 20px;
    --defaultPagePaddingTop: 50px;

    --defaultButtonPadding: 0.7em 1.1em;
    --defaultButtonHeight: 45px;

    --defaultInputPadding: 10px;

    --defaultRadius: 3px;

    --fontSizeInput: 1em;

    --animDefault: 0.35s;

    //--------------------------------------------------------------------------------

    // new version (use these):

    --default-radius: 7px;
    --default-radius-x2: 14px;

    --font: "DIN Condensed", "Open Sans", "sans-serif", "arial";
    --font-bold: "DIN Condensed", "Open Sans Bold", sans-serif, "arial";
    --font-base-size: 16px;

    --color-red: #ff7158;
    --color-green: #66ffcc;
    --color-green-2: #3ec692;
    --color-green-3: #2af378;
    --color-blue-3: #89e0f4;
    --color-blue-2: #37c6f7;
    --color-blue: #3976ee;
    --color-purple: #623fea;
    --color-magenta: #a61fe5;
    --color-magenta-2: #f33fff;
    --color-orange: #eaa824;
    --color-white: #ffffff;
    --color-black: #000000;

    --color-link: #88d9e0;
    --color-link-hover: #fff;
    --color-font: #fff;
    --color-font-grey: #333;
    --color-background: #fff; //#123;

    --button-radius: var(--default-radius);
    --button-color-bg: var(--color-blue);
    --button-color-font: var(--color-white);
    --button-color-bg-hover: var(--color-blue);
    --button-text-color: var(--color-blue);
    --button-text-color-hover: white;

    --input-radius: var(--default-radius);
    --input-padding: 10px;
    --input-font-size: 1.18rem;

    // Text sizing:
    --font-size-h1: 1rem;
    --font-size-h2: 1.2;

    --gutter-default: 1vw;

    @include mobile {
        --defaultPagePadding: 10px;
        --defaultItemPadding: 10px;
        --defaultRadius: 7px;

        // new:

        --gutter-default: 2vw;
    }
}

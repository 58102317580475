@import "/src/styles/includes";

#contact {
    .content {
        .section {
            @include flex-row;
            flex-wrap: nowrap;
            width: 100%;
            padding: clamp(20px, 5vw, 40px);

            .header {
                font-size: clamp(16pt, 7vw, 24pt);
                margin-bottom: clamp(5px, 1vw, 10px);
            }

            .text {
                font-size: clamp(12pt, 1.5vw, 16pt);
            }
        }

        .location {
            .info {
                padding-right: clamp(40px, 20vw, 80px);
                margin: 5px 0;

                .office {
                    margin-top: clamp(10px, 5vw, 40px);
                    color: var(--color-red);
                    font-size: clamp(14pt, 6vw, 20pt);
                    margin-bottom: clamp(2px, 0.5vw, 5px);
                }
            }

            .map,
            .address {
                flex: 1 0 50%;
                padding: clamp(5px, 2vw, 50px);
            }

            .map {
                min-height: 31vw;
                width: 100%;

                @include mobile {
                    min-height: 240px;
                    height: 70vw;
                }

                a {
                    display: block;
                    height: auto;
                    width: clamp(300px, 50vw, 800px);
                    margin: 0 0 0 auto;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 5px;
                    }
                }
            }
        }

        .social {
            background: var(--color-red);
            padding: clamp(15px, 3vw, 20px);
            @include flex-row-center-both;

            .icon {
                margin: 0 clamp(30px, 8vw, 50px);
                width: clamp(32px, 14vw, 64px);
                height: clamp(32px, 14vw, 64px);

                a {
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    @include hover();
                }
            }
        }

        .quote {
            @include flex-col-center-both;
            margin-top: 10px;

            .subtitle {
                margin: clamp(5px, 2vw, 10px);
                font-size: clamp(12pt, 1.5vw, 16pt);
                text-align: center;
            }

            .form {
                width: clamp(600px, 50vw, 800px);
                max-width: 100vw;
                margin-top: clamp(10px, 1vw, 40px);

                .row {
                    @include flex-row;
                    margin: 2.5px 0;
                    width: 100%;

                    form {
                        width: 100%;
                    }

                    input,
                    textarea {
                        flex: 1 1 auto;
                        margin: 10px;
                        border-radius: 0;
                        border: none;
                        background: white;
                        color: black;
                        font-family: "DIN Condensed";
                        box-shadow: none;
                        min-width: 40px;
                    }

                    textarea {
                        min-height: 150px;
                    }
                }

                .btn {
                    width: 100%;
                    padding: 10px;

                    .button {
                        width: 100%;
                        max-width: 250px;
                        margin: 0 auto;
                    }
                }

                .status {
                    width: 100%;
                    color: var(--color-red);
                    text-align: center;
                    font-size: clamp(14pt, 2vw, 18pt);
                    height: 1.5em;

                    &.sent {
                        color: white;
                    }
                }
            }
        }
    }

    @include mobile {
        .content {
            .section {
                &.location {
                    @include flex-col;
                }

                .map,
                .address {
                    flex: 1 0 auto;

                    a {
                        margin: 0;
                        width: 100%;
                    }

                    .info {
                        padding-right: clamp(20px, 5vw, 40px);
                    }
                }
            }

            .social {
                .icon {
                    margin: 0 clamp(15px, 2vw, 50px);
                    width: clamp(32px, 12vw, 64px);
                    height: clamp(32px, 12vw, 64px);
                }
            }

            .quote {
                .form {
                    width: clamp(300px, 90vw, 1024px);

                    .row {
                        @include flex-col;

                        input,
                        textarea {
                            display: block;
                            flex: 1 0 100%;
                        }
                    }

                    .btn .button {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

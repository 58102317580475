.blobs-logo {
    @include flex-col-center-both;
    position: relative;
    min-width: 50px;
    min-height: 50px;
    max-width: 300px;
    max-height: 300px;

    img {
        width: auto;
        max-width: 100%;
    }

    &.small {
        min-width: 100px;
        width: 5vw;
    }

    &.page {
        min-width: 100px;
        min-height: 100px;
    }

    @include mobile {
        //min-width: 200px;

        &.small {
            min-width: 100px;
            width: 10vw;
        }
    }
}

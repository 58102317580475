$padFix: 0.38rem;
$padFixMobile: 0.1rem;
$btnPadRight: calc(calc(var(--button-height) / 2) + 2px);

.button {
    position: relative;
    @include flex-center-both;
    @include font-button;
    font-weight: 700;
    min-width: 80px;
    // padding-top: $padFix;
    min-height: var(--button-height);
    height: var(--button-height);
    color: var(--button-text-color);

    // disable selection highlight on click:
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: var(--button-radius);
    text-rendering: optimizeLegibility;

    span {
        display: inline;
        width: auto;
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    a {
        width: 100%;
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
    }

    > div {
        @include flex-col-center-both;
        width: 100%;
        height: 100%;
    }

    span,
    a {
        @include flex-col-center-both;
        width: 100%;
        height: 100%;
        position: relative;
        top: 1px;
    }

    .inner {
        @include flex-center-both;
        max-height: 100%;
        text-align: center;
        //padding: 5px 0 0; // todo: fix per browser / tweak
    }

    &.bold {
        font-weight: bold;
    }

    &.nh {
        height: auto;
    }

    &:hover {
        cursor: pointer;
        color: var(--button-text-color-hover);
    }

    // colors /////////

    &.outline {
        &.white {
            color: #ccc;
            border: 1px solid #ccc;
            transition:
                color 0.2s linear,
                border-color 0.2s linear;

            a {
                color: #ccc;
                transition:
                    color 0.2s linear,
                    border-color 0.2s linear;
            }

            &:hover {
                color: var(--color-white);
                border: 1px solid var(--color-white);
                transition:
                    color 0.2s linear,
                    border-color 0.2s linear;

                a {
                    color: var(--color-white);
                    transition:
                        color 0.2s linear,
                        border-color 0.2s linear;
                }
            }
        }

        &.red {
            color: var(--color-red);
            border: 1px solid var(--color-red);
            transition:
                color 0.2s linear,
                border-color 0.2s linear;

            a {
                color: var(--color-red);
                transition:
                    color 0.2s linear,
                    border-color 0.2s linear;
            }

            &:hover {
                color: var(--color-white);
                background: var(--color-red);
                transition:
                    color 0.2s linear,
                    border-color 0.2s linear,
                    background-color 0.2s linear;

                a {
                    color: var(--color-white);
                    transition:
                        color 0.2s linear,
                        border-color 0.2s linear,
                        background-color 0.2s linear;
                }
            }
        }

        @include mobile {
            &.white {
                color: #fff;
                border: 1px solid #fff;

                a {
                    color: #fff;
                }
            }
        }
    }

    &.solid {
        background: black;

        &.red {
            background: var(--color-red);
            color: white;
            transition: background-color 0.1s linear;

            a {
                color: white;
            }

            &:hover {
                background: lighten(#bc3232, 10%);
                transition: background-color 0.1s linear;
            }
        }
    }

    // TODO: print these as functional sass outputs...

    &.color-blue {
        //color: var(--color-blue-light-variant);
        @include gradient-blue-hover;

        &:hover {
            //color: var(--color-blue-light);
            @include gradient-blue-hover-2;
        }

        &.outline {
            color: var(--button-background-color);
            background: none;
            border: 2px solid var(--button-background-color);

            &:hover {
                color: var(--color-blue-light);
                border: 2px solid var(--color-blue-light);
            }
        }
    }

    &.color-green {
        border: 2px solid var(--color-green);
        color: var(--color-green);

        &.filled {
            background: var(--color-green);
            color: var(--color-blue-dark);
        }
    }

    &.color-light {
        border: 2px solid var(--color-white);
        color: var(--color-white);

        &.filled {
            background: var(--color-white);
            color: var(--color-blue);
        }
    }

    &.color-red {
        border: 2px solid var(--color-red);
        color: var(--color-red);

        &.filled {
            background: var(--color-red);
            color: var(--color-white);
        }
    }

    &.text-blue {
        color: var(--color-blue) !important;
    }

    &.text-green {
        color: var(--color-green) !important;
    }

    &.text-white {
        color: white !important;
    }

    &.text-red {
        color: var(--color-red) !important;
    }

    //////////////

    &.has-icon {
        @include flex-center-x;
        // padding-left: 1rem;
        // padding-right: $btnPad;

        &.icon-left {
            // padding-left: clamp(5px, 0.25vw, 10px);
            // padding-right: 1rem;
        }
    }

    .icon {
        @include flex-center-x;
        position: absolute;
        display: block;
        justify-self: flex-end;
        margin: 0 0 0 auto;
        width: 0;
        min-width: 0;
        right: $btnPadRight;

        > * {
            display: block;
            position: absolute;
            right: -0.9em; //-$btnPad;
            //top: -.2em;
            min-width: 1.4rem;
            max-width: 28px;
            transform: translateY(-50%);
        }

        > .loader {
            .loader-ring {
                div {
                    top: 2px; // special case to move the loader down a few pixels...
                }
            }
        }
    }

    &.text-left {
        justify-content: flex-start;
    }

    // sizes

    &.size-small {
        @include font-button;
        font-weight: 300;
        font-size: 0.65rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        min-width: 60px;
        padding-top: 1px;
        min-height: calc(var(--button-height) / 1.5);
        height: calc(var(--button-height) / 1.5);
    }

    &.size-large {
        @include font-button;
        font-weight: 500;
        font-size: 1.25rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        min-width: 100px;
        padding-top: calc(#{$padFix} * 1.5);
        min-height: calc(var(--button-height) * 1.5);
        height: calc(var(--button-height) * 1.5);
    }

    &.flex-inline {
        @include flex-inline;
    }

    //////////////////////////////////////

    @include mobile {
        width: calc(100vw - 15px);
        padding-top: 0;

        span,
        a {
            position: relative;
            top: 2px;
        }

        .inner {
            @include flex-center-x;
            // padding: 4px 0 0; // todo: fix per browser / tweak
            line-height: 0.75rem;
        }

        .icon {
            > * {
                top: -1px;
                right: -1em;
                min-width: 24px;
                max-width: 24px;
            }
        }

        &.simple {
            padding-top: 0rem;
        }

        &.bold {
            padding-top: 2px;

            // &.has-icon {
            //     .icon {
            //         > * {
            //             //padding-top: 3px;
            //             right: -.7em;
            //             top: 0;//-.1em;
            //             min-width: 24px;
            //             max-width: 24px;
            //         }
            //     }
            // }
        }
    }
}

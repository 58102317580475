// header text

@mixin header {
    font-weight: bold;
    //@at-root: .5rem 0;
}

@mixin h1 {
    @include header;
    font-size: 2.25rem;
}

@mixin h2 {
    @include header;
    font-size: 2rem;
}

@mixin h3 {
    @include header;
    font-size: 1.75rem;
}

@mixin h4 {
    @include header;
    font-size: 1.5rem;
}

@mixin h5 {
    @include header;
    font-size: 1.25rem;
}

@mixin h6 {
    @include header;
    font-size: 1.15rem;
}

// regular text

@mixin t1 {
    font-size: 1em;
}

@mixin t2 {
    font-size: 1.25rem;
}

@mixin t3 {
    font-size: 1.5rem;
}

@mixin t4 {
    font-size: 1.75rem;
}

@mixin t5 {
    font-size: 2rem;
}

@mixin t6 {
    font-size: 2.25rem;
}

@mixin t7 {
    font-size: 2.5rem;
}

@mixin t8 {
    font-size: 2.75rem;
}

@mixin t9 {
    font-size: 3rem;
}

// sub-text

@mixin s1 {
    font-size: 1em;
}

@mixin s2 {
    font-size: 1.25rem;
}

@mixin s3 {
    font-size: 1.5rem;
}

@mixin s4 {
    font-size: 1.75rem;
}

@mixin s5 {
    font-size: 2rem;
}

// misc

@mixin text-gradient($angle: 45deg, $colorFrom: #111, $colorTo: #558) {
    background: -webkit-linear-gradient($angle, $colorFrom, $colorTo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.panel {
    border-radius: var(--defaultRadius);
    margin: 0 auto;
    overflow: hidden;
    background: white;
    box-shadow: 2px 2px 10px rgba(0,0,0,.3);

    .header {
        @include flex-center-y();
        padding: 10px;
        font-weight: bold;
        background: rgba(0,0,0,.2);
        box-shadow: 0px 0px 4px rgba(0,0,0,.5);
    }

    .inner {
        padding: calc(var(--defaultItemPadding)*2) var(--defaultItemPadding);
    }

    .actions {
        padding: 10px 20px;
        @include flex-center-y();
        background: rgba(0,0,0,.05);

        .button {
            margin: 0 5px;
        }
    }

    &.full {
        width: 100%;
    }

    &.sm {
        width: 20%;
    }

    &.md {
        width: 40%;
    }

    &.lg {
        width: 80%;;
    }


}
@import "/src/styles/includes";

#clients {
    $border: 5px solid white;
    $borderMobile: 3px solid white;

    .content {
        .grid {
            @include flex-row-center-both;
            flex-wrap: wrap;
            width: clamp(400px, 80vw, 1200px);
            min-width: 400px;
            margin: 0 auto;
            align-items: flex-start;
            justify-content: flex-start;
            box-sizing: content-box;

            li {
                @include flex-col-center-both;
                width: 33%;
                height: clamp(300px, 18vw, 450px);

                img {
                    width: 100%;
                    max-width: 400px;
                    max-height: 100%;
                    object-fit: contain;
                    padding: clamp(40px, 7vw, 100px) clamp(50px, 7vw, 100px);
                    @include hover();
                }

                border-right: $border;
                border-bottom: $border;
            }

            :nth-child(3n) {
                border-right: none;
            }

            // last 3 in grid
            li:nth-last-child(-n + 3) {
                border-bottom: none;
            }
        }
    }

    @include mobile {
        .content {
            min-width: 200px;
            padding-top: 5vw;

            .grid {
                width: clamp(300px, 90vw, 1200px);
                min-width: 300px;
                padding: 0;

                li {
                    height: clamp(120px, 28vw, 400px);
                    border-right: $borderMobile;
                    border-bottom: $borderMobile;

                    img {
                        min-height: 60px;
                        padding: clamp(10px, 7vw, 100px);
                    }
                }
            }
        }
    }
}

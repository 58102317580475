@import "/src/styles/includes";

#audio-danley {
    .content {
        .items {
            @include flex-col-center-x;

            .item {
                position: relative;
                @include flex-col-center-x;
                @include hover(0.7);
                margin: clamp(15px, 5vw, 100px);
                transition: opacity 0.2s linear;

                .image {
                    position: relative;
                    @include flex-col-center-both;
                    max-width: 400px;
                    margin-bottom: 5px;
                    height: auto; // clamp(200px, 30vw, 400px);

                    img {
                        height: 100%;
                        width: auto;
                        max-width: 400px;
                        max-height: 250px;
                    }
                }

                .brand-logo {
                    height: clamp(15px, 5vw, 30px);
                    margin: clamp(10px, 2vw, 20px) 0 0;

                    img {
                        height: 100%;
                    }
                }

                .title {
                    text-transform: uppercase;
                    font-size: clamp(15pt, 2vw, 28pt);
                    color: var(--color-red);
                    margin: clamp(5px, 1vw, 10px) 0 clamp(10px, 1vw, 20px); //clamp(10px, 1vw, 40px);
                }

                .description {
                    max-width: clamp(300px, 40vw, 600px);
                    text-align: center;
                    font-size: clamp(14pt, 1.5vw, 24pt);
                    line-height: clamp(2rem, 2vw, 2.5rem);
                }
            }

            .button {
                width: 200px;
                margin: 0 auto;
            }
        }
    }

    @include mobile {
        .content {
            .items {
                padding: var(--gutter) 0;

                .item {
                    opacity: 1;
                    margin: clamp(30px, 12vw, 100px);

                    .image {
                        max-width: 200px;
                        max-height: 200px;
                        height: auto; // clamp(200px, 30vw, 400px);

                        img {
                            max-width: 200px;
                            max-height: 200px;
                        }
                    }
                }
            }
        }
    }
}

.center {
    text-align: center;
}

.text-small {
    font-size: .75rem;
}

.text-large {
    font-size: 2rem;
}

.h1, h1 {
    @include h1;
}

.h2, h2 {
    @include h2;
}

.h3, h3 {
    @include h3;
}

.h4, h4 {
    @include h4;
}

.h5, h5 {
    @include h5;
}

.h6, h6 {
    @include h6;
}


.t1 {
    @include t1;
}

.t2 {
    @include t2;
}

.t3 {
    @include t3;
}

.t4 {
    @include t4;
}

.t5 {
    @include t5;
}


.s1 {
    @include s1;
}

.s2 {
    @include s2;
}

.s3 {
    @include s3;
}

.s4 {
    @include s4;
}

.s5 {
    @include s5;
}

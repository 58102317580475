@import "/src/styles/includes";

#page-not-found {
    h1 {
        font-size: 5rem;
        margin: 2rem;
    }

    h2 {
        font-size: 3rem;
        margin: 4rem 2rem;
        color: #aaa;
    }
}
